import React, { useState, useEffect, useRef } from "react";

import {
  Col,
  Row,
  Divider,
  Input,
  Form,
  Button,
  Card,
  InputNumber,
  Checkbox,
  Modal,
  Select,
  notification,
  Layout,
  Switch,
} from "antd";
import { RollbackOutlined } from "@ant-design/icons";
import Cleave from "cleave.js/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PosCommissionTable from "../../src/routes/PosCollection/PosCommissionTable";
import PosSelectableInstallmentTable from "../../src/routes/PosCollection/PosSelectableInstallmentTable";

import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "../../src/forms/Helper";
import { ColumnHelper } from "util/TableHelper";
import { useHistory } from "react-router";
import paymentLogoBand from "../assets/images/payment-logo-band.png";
import { footerText } from "util/config";
import PosCollectionAmountSummary from "./../routes/PosCollection/PosCollectionAmountSummary";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};
const { TextArea } = Input;
const PosCollectionPage = (props) => {
  const { Footer } = Layout;

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [creditCardType, setCreditCardType] = useState("");
  const [form] = Form.useForm();
  const [email, setEmail] = useState("");
  const [paymentModal, setPaymentModal] = useState({
    visible: false,
    source: null,
  });
  const [availableProviders, setAvailableProviders] = useState({
    loading: true,
    data: [],
  });
  const [availableInstallments, setAvailableInstallments] = useState({
    loading: true,
    data: [1],
  });
  const [activeProviderId, setActiveProviderId] = useState();
  const [currencyCodes, setCurrencyCodes] = useState(["TRY", "USD", "EUR"]);
  const [commissionOptions, setCommissionOptions] = useState({
    loading: true,
    data: {
      useCommissionsForAvailableInstallment: false,
      useFirmGroupCodeForMaxInstallment: false,
      isSelectableInstallmentTable: false,
      posCollectionDescription: null,
      commissions: [],
    },
  });
  const [activeAmount, setActiveAmount] = useState(0);
  const [payableAmount, setPayableAmount] = useState(0);
  const [radioValue, setRadioValue] = useState();
  const iframeRef = useRef();
  const [logoUrl, setLogoUrl] = useState();
  const [tenantPaymentGuid, setTenantPaymentGuid] = useState();
  const [isPaymentWithParams, setIsPaymentWithParams] = useState(false);
  const [disabledAmount, setDisabledAmount] = useState(false);

  const [firmId, setFirmId] = useState("");
  const [posCollectionTransactionId, setPosCollectionTransactionId] =
    useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [callBackUrl, setCallbackUrl] = useState("");

  const [tenantInfo, setTenantInfo] = useState({});

  const [confidentialAgreement, setconfidentialAgreement] = useState(
    "/gizlilik-ve-guvenlik-politikasi.pdf"
  );
  const [salesAgreement, setSalesAgreement] = useState("/tuketici-haklari.pdf");

  const [ozanIframe, setOzanIframe] = useState(false);
  const [useAdditionalInformation, setUseAdditionalInformation] =
    useState(false);
  const [isRubikFu, setIsRubikFu] = useState(false);
  const [b2bFilePath, setB2bFilePath] = useState();
  const [activeCommissionRate, setActiveCommissionRate] = useState(0.0);
  const [emailRequired, setEmailRequired] = useState(false);
  const [isUseB2BCurrencyExChange, setIsUseB2BCurrencyExChange] =
    useState(false);

  const [currencyExchangeRates, setCurrencyExchangeRates] = useState({
    data: {
      usdAmount: null,
      euroAmount: null,
      tlAmount: null,
    },
  });
  const [tenantId, setTenantId] = useState("");
  const [activeCurrency, setActiveCurrency] = useState("TRY");
  const [paymentMethodList, setPaymentMethodList] = useState({
    loading: true,
    data: [],
  });
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();

  const getTenantInfo = (tenantId) => {
    RequestHandler.get(`/FirmAuth/GetTenantById/${tenantId}`).then(
      (response) => {
        if (response.success) {
          setTenantInfo(response.data);
          setB2bFilePath(response.data.b2BFilePath);
          setUseAdditionalInformation(
            response.data.isPosTransactionUseAdditionalField
          );
          setIsRubikFu(response.data.isRubikFu);
          if (response.data.confidentialityAgreementPath) {
            setconfidentialAgreement(
              response.data.confidentialityAgreementPath
            );
          }

          if (response.data.salesAgreementPath) {
            setSalesAgreement(response.data.salesAgreementPath);
          }
          setIsUseB2BCurrencyExChange(response.data.isUseB2BCurrencyExChange);
          setTenantId(response.data.id);
          if (response.data.isPosTransactionUseAdditionalField) {
            RequestHandler.get(
              `/PayWithoutLogin/GetPaymentMethods?tenantId=${response.data.id}`
            ).then((response) => {
              setPaymentMethodList({
                data: response.data,
                loading: false,
              });
            });
          }
        }
      }
    );
  };

  useEffect(() => {
    if (!activeProviderId) return;
    let currencies = ["TRY", "USD", "EUR"];

    //Param Pos
    if (activeProviderId === 889) {
      currencies = ["TRY"];
    }

    setCurrencyCodes(currencies);
    setFieldsValue({ currencyCode: currencies[0] });

    if (!commissionOptions.data.isSelectableInstallmentTable) {
      getAvailableInstallments(activeProviderId);
    }
  }, [activeProviderId]);

  useEffect(() => {
    let basePath = window.location.href.split("/pay-without-login")[0];
    RequestHandler.get(`/FirmAuth/GetLoginEndpoints`).then((response) => {
      response.domain.map((item) => {
        if (basePath == item.endpoint) {
          setLogoUrl(item.logoUrl);
          getTenantInfo(item.tenantPaymentGuid);
          setTenantPaymentGuid(item.tenantPaymentGuid);
          getAvailableProviders(item.tenantPaymentGuid);
          getCommissionOptions(item.tenantPaymentGuid);
          setEmail(item.email);

          if (item.isPaymentWithParams) {
            if (
              props.match.params.referenceNumber !== undefined &&
              props.match.params.amount !== undefined
            ) {
              decryptParameters(item.tenantPaymentGuid);
            } else {
              notification.error({
                message: "Sipariş numarası veya tutar bulunamadı. ",
                duration: 3,
              });
            }
          }
        }
      });
    });
    if (props.data) {
      setFieldsValue(props.data);
    }
  }, [props.data]);

  const getFirmId = (tenantPaymentGuid, firmbranchNumber) => {
    RequestHandler.post(
      `/PayWithoutLogin/GetByFirmBranchNumberWithTenantPaymentGuid`,
      {
        tenantPaymentGuid: tenantPaymentGuid,
        firmbranchNumber: firmbranchNumber,
      },
      false
    ).then((response) => {
      if (response) {
        setFirmId(response.data.id);
      }
    });
  };

  useEffect(() => {
    fetch("meta.json", {
      headers: {
        "cache-control": "no-cache",
        pragma: "no-cache",
      },
    }).then(async (response) => {
      const data = await response.json();
      const currentVersion = document.cookie.replace(
        /(?:(?:^|.*;\s*)_v\s*\=\s*([^;]*).*$)|^.*$/,
        "$1"
      );

      if (currentVersion !== data.version) {
        const versionCookie = `_v=${data.version}; path=/;`;
        document.cookie = versionCookie;

        if ("caches" in window) {
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });

          window.location.reload();
        }
      }
    });
  }, []);

  async function decryptParameters(tenantPaymentGuidValue) {
    await RequestHandler.post(
      `/PayWithoutLogin/GetDecryptParameters`,
      {
        encryptAmountString: props.match.params.amount,
        referenceNumber: props.match.params.referenceNumber,
        firmbranchNumber: props.match.params.firmbranchNumber,
        callbackUrl: props.match.params.callbackUrl,
      },
      false
    ).then((response) => {
      if (response.success) {
        setActiveAmount(parseFloat(response.data.amount));
        setFieldsValue({ amount: parseFloat(response.data.amount) });
        setIsPaymentWithParams(true);
        setReferenceNumber(response.data.referenceNumber);
        setDisabledAmount(true);
        getFirmId(tenantPaymentGuid, response.data.firmbranchNumber);
        setCallbackUrl(response.data.callbackUrl);
      } else {
        notification.error({
          message:
            "Sipariş tutarı veya referans numarası doğru değil. Lütfen alışveriş sayfası yetkilileriyle iletişime geçin.",
          duration: 3,
        });
      }
    });
  }

  const getAvailableProviders = (value) => {
    RequestHandler.get(
      `/PayWithoutLogin/AvailableProviders?tenantPaymentGuid=${value}`
    ).then((response) => {
      if (response.success) {
        setAvailableProviders({
          data: response.data,
          loading: false,
        });
        setActiveProviderId(response.data?.at(0)?.id);
      } else {
        setAvailableProviders({ ...availableProviders, loading: false });
      }
    });
  };

  const getCommissionOptions = (value, paymentSetId) => {
    let queryString = `tenantPaymentGuid=${value}`;
    if (paymentSetId) {
      queryString += "&paymentSetId=" + paymentSetId;
    }

    RequestHandler.get(
      `/PayWithoutLogin/CommissionOptions?${queryString}`
    ).then((response) => {
      if (response.success) {
        setCommissionOptions({
          data: response.data,
          loading: false,
        });
      } else {
        setCommissionOptions({ ...commissionOptions, loading: false });
      }
    });
  };

  const numberWithSpaces = (value, pattern = "0### ### ## ##") => {
    if (!value) return;
    var i = 0,
      phone = value.toString();
    return pattern.replace(/#/g, (_) => phone[i++]);
  };

  const getAvailableInstallments = (bankId) => {
    setAvailableInstallments({ ...availableInstallments, loading: true });

    RequestHandler.post(
      `/PayWithoutLogin/AvailableInstallments`,
      { bankId, tenantPaymentGuid },
      false
    ).then((response) => {
      if (!response.success) {
        setAvailableInstallments({ ...availableInstallments, loading: false });
        setFieldsValue({ installmentCount: 1 });
        return;
      }

      const { installments, defaultInstallment } = response.data;
      setAvailableInstallments({ data: installments, loading: false });

      setFieldsValue({ installmentCount: defaultInstallment });
    });
  };

  const onFinish = (values) => {
    let expireMonth, expireYear;

    if (values.expiration) {
      [expireMonth, expireYear] = values.expiration.split("/");
    }

    var bankIdCommissionIdWithInstallment = values.bankIdInstallment.split("/");
    values.bankId = bankIdCommissionIdWithInstallment[0];
    values.installmentCount = bankIdCommissionIdWithInstallment[3];
    values.cardType = bankIdCommissionIdWithInstallment[2];
    values.posCollectionCommissionDefinitionId =
      bankIdCommissionIdWithInstallment[1];

    if (values.bankId === "1011") {
      setOzanIframe(true);
    }

    RequestHandler.post(
      `/PayWithoutLogin/SalesRequest`,
      {
        holderName: values.holderName,
        cardNumber: values.cardNumber,
        cvv: values.cvv,
        expireMonth: expireMonth,
        expireYear: expireYear,
        amount: payableAmount,
        currencyCode: values.currencyCode,
        bankId: values.bankId,
        installmentCount: values.installmentCount,
        mobilePhone: values.MobilePhone,
        description: values.description,
        email: email,
        customerEmail: values.email,
        tenantPaymentGuid: tenantPaymentGuid,
        firmId: firmId != "" ? firmId : null,
        callbackUrl: callBackUrl,
        callbackUrlRequestMethod: isPaymentWithParams ? "GET" : null,
        cardType: values.cardType,
        tckn: values.tckn,
        contractNumber: values.contractNumber,
        branchCode: values.branchCode,
        memberNumber: values.memberNumber,
        customerInformation: values.customerInformation,
        netAmount: values.amount,
        posCollectionCommissionDefinitionId:
          values.posCollectionCommissionDefinitionId,
        paymentMethod: selectedPaymentMethod?.name,
        paymentSetId: selectedPaymentMethod?.paymentSetId,
      },
      false
    ).then((response) => {
      setLoading(false);
      if (response.success) {
        //fill html
        setPaymentModal({ visible: true, source: response.data.html });
        setPosCollectionTransactionId(response.data.posCollectionTransactionId);
        if (!isPaymentWithParams) {
          form.resetFields();
        }

        setFieldsValue({
          bankId: values.bankId,
        });

        if (props.onSuccess) props.onSuccess();
      }
    });
  };

  const onChangeActiveProvider = (value) => {
    setActiveProviderId(value);
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const onChangeRadio = (radioVal, values) => {
    setRadioValue(radioVal);
    setFieldsValue(values);
  };

  const setFieldsValue = (values) => {
    const currentValues = form.getFieldsValue();
    const newValues = { ...currentValues, ...values };
    form.setFieldsValue(newValues);

    onFormValuesChange(values, newValues);
  };

  const onFormValuesChange = (changedValues, allValues) => {
    var activeCommissions = commissionOptions.data.commissions.find(
      (x) => x.bankId === allValues.bankId && !x.isVisaMasterCard
    );

    var installmentCount = allValues.installmentCount;
    if (allValues.bankIdInstallment) {
      var bankIdCommissionIdWithInstallment =
        allValues.bankIdInstallment.split("/");

      activeCommissions = commissionOptions.data.commissions.find(
        (x) =>
          x.id === bankIdCommissionIdWithInstallment[1] && !x.isVisaMasterCard
      );
      installmentCount = bankIdCommissionIdWithInstallment[3];
    }

    if (
      bankIdCommissionIdWithInstallment &&
      bankIdCommissionIdWithInstallment[0] === "856"
    ) {
      setEmailRequired(true);
    } else {
      setEmailRequired(false);
    }

    if (!activeCommissions) {
      setPayableAmount(
        calculatePayableAmount(allValues.amount, 0, allValues.bankId, false)
      );

      setActiveCommissionRate(0);
      return;
    }
    const commissionRate =
      activeCommissions["commissionRate" + installmentCount];
    if (!(allValues.amount > 0 && commissionRate >= 0)) {
      setPayableAmount(
        calculatePayableAmount(allValues.amount, 0, allValues.bankId, false)
      );
      setActiveCommissionRate(commissionRate);
      return;
    }

    setPayableAmount(
      calculatePayableAmount(
        allValues.amount,
        commissionRate,
        allValues.bankId,
        activeCommissions["isCommissionFromDealer"]
      )
    );
    setActiveCommissionRate(commissionRate);
  };

  const calculatePayableAmount = (
    amount,
    commissionRate,
    bankId,
    isCommissionFromDealer
  ) => {
    const { useCommissionsForAvailableInstallment } = commissionOptions.data;
    let payableAmount = amount;
    if (useCommissionsForAvailableInstallment) {
      payableAmount = amount + (amount / 100) * commissionRate;
    }

    if (isCommissionFromDealer && useCommissionsForAvailableInstallment) {
      payableAmount = (amount / (100 - commissionRate)) * 100;
    }

    if (bankId == 856) {
      payableAmount = amount / ((100 - commissionRate) / 100);
    }

    return payableAmount;
  };

  const isExternalPaymentFormProvider = false;

  const afterPayment = () => {
    notification.warning({
      message: "Lütfen bekleyiniz...",
      duration: 2,
    });

    RequestHandler.get(
      `/PayWithoutLogin/PosCollectionTransactionGetById/${posCollectionTransactionId}`
    ).then((response) => {
      if (response.success) {
        if (response.data.success) {
          form.resetFields();
          notification.success({
            message: "Alışveriş sitesine yönlendiriliyorsunuz",
            duration: 2,
          });
          setTimeout(() => {
            window.location.href = callBackUrl;
          }, 2000);
        } else {
          notification.error({
            message: "Ödeme başarısız. Lütfen Tekrar deneyiniz. ",
            duration: 3,
          });
        }
      } else {
        notification.error({
          message: "Ödeme başarısız. Lütfen Tekrar deneyiniz. ",
          duration: 3,
        });
      }
    });
  };

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  useEffect(() => {
    if (isNaN(payableAmount)) {
      setPayableAmount(0);
    } else {
      setPayableAmount(payableAmount);
    }
  }, [payableAmount]);

  function onChangeEmailRequiredSwitch(checked) {
    setEmailRequired(checked);
  }

  const handleAmountChange = () => {
    if (isUseB2BCurrencyExChange && typeof activeAmount === "number") {
      RequestHandler.post(
        `/PayWithoutLogin/GetPosCollectionTransactionExchangeAmounts`,
        {
          amount: activeAmount,
          currencyCode: activeCurrency,
          tenantId: tenantId,
        },
        false
      ).then((response) => {
        if (response?.success) {
          setCurrencyExchangeRates({
            data: {
              usdAmount: response.data.usdAmount,
              euroAmount: response.data.euroAmount,
              tlAmount: response.data.tlAmount,
            },
          });
        }
      });
    }
  };

  const combinedBlurFunctionForAmount = (e) => {
    handleAmountChange();
  };

  useEffect(() => {
    if (isUseB2BCurrencyExChange) {
      RequestHandler.post(
        `/PayWithoutLogin/GetPosCollectionTransactionExchangeAmounts`,
        {
          amount: activeAmount,
          currencyCode: activeCurrency,
          tenantId: tenantId,
        },
        false
      ).then((response) => {
        if (response?.success) {
          setCurrencyExchangeRates({
            data: {
              usdAmount: response.data.usdAmount,
              euroAmount: response.data.euroAmount,
              tlAmount: response.data.tlAmount,
            },
          });
        }
      });
    }
  }, [activeCurrency]);

  useEffect(() => {
    if (useAdditionalInformation) {
      getCommissionOptions(
        tenantPaymentGuid,
        selectedPaymentMethod?.paymentSetId
      );

      clearRadioValue();
    }
  }, [selectedPaymentMethod]);

  const clearRadioValue = () => {
    setRadioValue(null);
    form.setFieldsValue({ bankIdInstallment: null });
  };

  return (
    <>
      <Row
        id="paymentArea"
        justify="center"
        align="middle"
        style={{
          paddingRight: "50px",
          paddingLeft: "50px",
          paddingTop: "50px",
        }}
      >
        <a
          onClick={() => history.goBack()}
          style={{ color: "black", marginRight: "auto" }}
        >
          {" "}
          <RollbackOutlined /> GERİ DÖN
        </a>
        <Col span={24}>
          <Card
            className="gx-card"
            loading={availableProviders.loading || commissionOptions.loading}
          >
            {availableProviders.data.length > 0 ? (
              <Form
                {...formItemLayout}
                validateMessages={defaultValidateMessages}
                form={form}
                name="PaymentForm"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                labelAlign="left"
                onValuesChange={onFormValuesChange}
                fields={[{ name: "payableAmount", value: payableAmount }]}
              >
                <>
                  {useAdditionalInformation && (
                    <>
                      <Divider>Ek Bilgiler</Divider>

                      <Row justify="center">
                        <Col xs={9} lg={6}>
                          <Form.Item
                            label="TC Kimlik Numarası"
                            name="tckn"
                            rules={[
                              {
                                required: true,
                                min: 11,
                                message:
                                  "TC Kimlik Numarası 11 Karakter Olmalıdır.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="TC Kimlik Numarası"
                              type="number"
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              maxLength="11"
                              onInput={maxLengthCheck}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={15} lg={6}>
                          <Form.Item
                            label="Sözleşme Numarası"
                            name="contractNumber"
                            rules={[
                              {
                                required: true,
                                message: "Sözleşme numarası boş bırakılamaz.",
                              },
                            ]}
                          >
                            <Input placeholder="Sözleşme Numarası" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row justify="center">
                        <Col xs={15} lg={6}>
                          <Form.Item label="Şube Adı/Kodu" name="branchCode">
                            <Input placeholder="Şube Adı/Kodu" />
                          </Form.Item>
                        </Col>
                        <Col xs={9} lg={6}>
                          <Form.Item label="Ek Alan" name="memberNumber">
                            <Input placeholder="Ek Alan" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row justify="center">
                        <Col xs={15} lg={6}>
                          <Form.Item
                            label="Ödeme Şekli"
                            name="paymentMethod"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              allowClear
                              placeholder="Ödeme Şekli"
                              onChange={(value, option) => {
                                setSelectedPaymentMethod(option);
                              }}
                            >
                              {paymentMethodList.data.map((item) => (
                                <Select.Option
                                  key={item.id}
                                  value={item.id}
                                  name={item.name}
                                  paymentSetId={item.paymentSetId}
                                >
                                  {`${item.name}`}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={9} lg={6}></Col>
                      </Row>
                    </>
                  )}

                  <Divider style={{ marginTop: -2 }}>Ödeme Bilgileri</Divider>

                  <Row justify="center">
                    <Col xs={12} lg={6}>
                      <Form.Item
                        label="Tutar"
                        name="amount"
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          decimalSeparator=","
                          precision={2}
                          min={0}
                          placeholder="0,00"
                          onChange={(value) => {
                            setActiveAmount(value);
                            form.setFieldsValue({ amount: value }); // Form değerini manuel olarak güncelle
                          }}
                          disabled={disabledAmount}
                          onBlur={combinedBlurFunctionForAmount}
                        />
                      </Form.Item>
                      <div style={{ marginTop: "10px" }}>
                        {currencyExchangeRates.data.usdAmount &&
                        currencyExchangeRates.data.euroAmount ? (
                          <div style={{ color: "#0047ca" }}>
                            {currencyExchangeRates.data.usdAmount} /{" "}
                            {currencyExchangeRates.data.euroAmount}
                          </div>
                        ) : currencyExchangeRates.data.euroAmount ? (
                          <div style={{ color: "#0047ca" }}>
                            {currencyExchangeRates.data.euroAmount}
                          </div>
                        ) : currencyExchangeRates.data.usdAmount ? (
                          <div style={{ color: "#0047ca" }}>
                            {currencyExchangeRates.data.usdAmount}
                          </div>
                        ) : null}
                      </div>
                      {currencyExchangeRates.data.tlAmount && (
                        <div style={{ marginTop: "10px" }}>
                          <div style={{ color: "#0047ca" }}>
                            {currencyExchangeRates.data.tlAmount}
                          </div>
                        </div>
                      )}
                    </Col>

                    <Col xs={12} lg={6}>
                      <Form.Item
                        label="Para Birimi"
                        name="currencyCode"
                        initialValue={currencyCodes[0]}
                      >
                        <Select
                          onChange={(value) => {
                            setActiveCurrency(value);
                          }}
                        >
                          {currencyCodes.map((currencyCode) => (
                            <Select.Option
                              key={currencyCode}
                              value={currencyCode}
                            >
                              {currencyCode}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {isExternalPaymentFormProvider &&
                    commissionOptions.data.posCollectionDescription && (
                      <Row
                        id="descriptionArea"
                        style={{ marginBottom: 12 }}
                        justify="center"
                      >
                        <Col xs={24} lg={12}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                commissionOptions.data.posCollectionDescription,
                            }}
                          />
                        </Col>
                      </Row>
                    )}

                  {!isExternalPaymentFormProvider && (
                    <>
                      <Row justify="center">
                        <Col xs={24} lg={12}>
                          <PosSelectableInstallmentTable
                            data={commissionOptions.data}
                            amount={activeAmount}
                            loading={commissionOptions.loading}
                            onChange={onChangeRadio}
                            radioValue={radioValue}
                            filePath={b2bFilePath}
                          />
                        </Col>
                      </Row>

                      {commissionOptions.data.posCollectionDescription && (
                        <Row
                          id="descriptionArea"
                          style={{ marginBottom: 12 }}
                          justify="center"
                        >
                          <Col xs={24} lg={12}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  commissionOptions.data
                                    .posCollectionDescription,
                              }}
                            />
                          </Col>
                        </Row>
                      )}

                      <Divider>Kart Bilgileri</Divider>

                      <Row justify="center">
                        <Col xs={24} lg={12}>
                          <Form.Item
                            label="Kart Üzerindeki İsim"
                            name="holderName"
                            rules={[{ required: true }]}
                          >
                            <Input placeholder="Ad Soyad" />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row justify="center">
                        <Col xs={24} lg={12}>
                          <Form.Item
                            label="Kart Numarası"
                            name="cardNumber"
                            valuePropName="rawValue"
                            rules={[
                              { required: true },
                              {
                                min: 15,
                                max: 16,
                              },
                            ]}
                          >
                            <Cleave
                              className="ant-input"
                              placeholder="0000 0000 0000 0000"
                              options={{
                                creditCard: true,
                                onCreditCardTypeChanged: (type) =>
                                  setCreditCardType(type),
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row justify="center">
                        <Col xs={12} lg={6}>
                          <Form.Item
                            label="Son Kullanma Tarihi"
                            name="expiration"
                            rules={[{ required: true }]}
                          >
                            <Cleave
                              className="ant-input"
                              placeholder="AA/YY"
                              options={{
                                date: true,
                                datePattern: ["m", "y"],
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={12} lg={6}>
                          <Form.Item
                            label="Güvenlik Kodu"
                            name="cvv"
                            rules={[{ required: true }]}
                          >
                            <Cleave
                              className="ant-input"
                              placeholder="CVV"
                              options={{
                                blocks: [4],
                                numericOnly: true,
                              }}
                              type="password"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                </>

                <Divider>Müşteri Bilgileri</Divider>

                <Row justify="center">
                  {isRubikFu && (
                    <>
                      <Col xs={24} lg={6}>
                        <Form.Item
                          label="Müşteri Bilgisi (Sözleşme Numarasında yer alan kişinin ismi yazılmalıdır)"
                          name="customerInformation"
                          rules={[
                            {
                              required: true,
                              message: "Müşteri bilgisi boş bırakılamaz.",
                            },
                          ]}
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Col>
                    </>
                  )}

                  <Col xs={24} lg={6}>
                    <Form.Item
                      label="Cep Numarası"
                      name="MobilePhone"
                      rules={[
                        {
                          required: true,
                          min: 12,
                          message: "Telefon numarasını eksiksiz giriniz.",
                        },
                      ]}
                    >
                      <PhoneInput
                        inputStyle={{ width: "100%" }}
                        country="tr"
                        masks={{ tr: "(...) ...-..-.." }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={6}>
                    <Form.Item
                      label="Email"
                      name="email"
                      hidden={isPaymentWithParams}
                      rules={[{ required: emailRequired, type: "email" }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify="center">
                  <Col xs={24} lg={6}>
                    <Form.Item
                      label="Açıklama"
                      name="description"
                      rules={[{ required: isPaymentWithParams ? false : true }]}
                      hidden={isPaymentWithParams}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={6}>
                    <Form.Item
                      label="İşlem Sonrası Dekont Al"
                      name="emailRequired"
                      valuePropName="checked"
                      hidden={isPaymentWithParams}
                    >
                      <Switch onChange={onChangeEmailRequiredSwitch} />
                    </Form.Item>
                  </Col>
                  {isRubikFu && (
                    <>
                      {" "}
                      <Col xs={24} lg={6}></Col>
                    </>
                  )}
                </Row>

                <PosCollectionAmountSummary
                  payableAmount={payableAmount}
                  activeAmount={activeAmount}
                  commissionRate={activeCommissionRate}
                />

                <Row justify="center">
                  <Col xs={24} lg={8}>
                    <Form.Item
                      name="agreement"
                      valuePropName="checked"
                      rules={[
                        {
                          required: true,
                          message: "Sözleşmeleri kabul etmeniz gerekmektedir.",
                        },
                      ]}
                    >
                      <Checkbox>
                        <a href={confidentialAgreement} target="_blank">
                          Gizlilik
                        </a>{" "}
                        ve{" "}
                        <a href={salesAgreement} target="_blank">
                          Tüketici Hakları
                        </a>{" "}
                        sözleşmelerini okudum, onaylıyorum.
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={4} style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => setLoading(true)}
                      loading={loading}
                    >
                      {payableAmount > 0
                        ? `(${ColumnHelper.renderDecimal(payableAmount)}) `
                        : undefined}
                      Öde
                    </Button>
                  </Col>
                </Row>
              </Form>
            ) : (
              "Firmanızın tanımlı bir ödeme sağlayıcısı bulunmuyor."
            )}
          </Card>
        </Col>
      </Row>

      <Footer>
        <Row justify="space-between" align="middle">
          <div className="gx-layout-footer-content">{footerText}</div>
          <div className="gx-layout-footer-content">
            <img
              src={paymentLogoBand}
              style={{ width: "100%", maxWidth: 450 }}
            />
          </div>
          <div className="gx-layout-footer-content">
            {numberWithSpaces(tenantInfo.phoneNumber)} <br />
            {tenantInfo.address}
          </div>
        </Row>
      </Footer>

      {!commissionOptions.loading &&
        !commissionOptions.data.isSelectableInstallmentTable &&
        commissionOptions.data.commissions.length > 0 && (
          <PosCommissionTable
            data={commissionOptions.data}
            amount={activeAmount}
            loading={commissionOptions.loading}
          />
        )}

      <Modal
        id="paymentModal"
        centered
        visible={paymentModal.visible}
        onCancel={() => {
          if (isPaymentWithParams) {
            afterPayment();
          }
          setPaymentModal({ ...paymentModal, visible: false });
        }}
        destroyOnClose={false}
        maskClosable={false}
        keyboard={false}
        width={600}
        footer={null}
      >
        {ozanIframe ? (
          <iframe
            id="paymentFrame"
            ref={iframeRef}
            style={{ width: "100%", border: 0 }}
            src={paymentModal.source}
            scrolling="auto"
            onLoad={() => {
              const iframe = iframeRef.current;
              iframe.style.height = iframe.scrollWidth + 150 + "px";
            }}
          />
        ) : (
          <iframe
            id="paymentFrame"
            ref={iframeRef}
            style={{ width: "100%", border: 0 }}
            srcDoc={paymentModal.source}
            sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
            scrolling="auto"
            onLoad={() => {
              const iframe = iframeRef.current;
              iframe.style.height = iframe.scrollWidth + 150 + "px";
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default PosCollectionPage;
