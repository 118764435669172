import React from "react";

const ColumnHelper = {
  renderDecimal: (data) =>
    data === null || data === "" || data === undefined ? 0 : data.toLocaleString("tr-TR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
    renderDecimalWith3Digits: (data) =>
      data.toLocaleString("tr-TR", {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      }),
};

const RowHelper = {
  renderRowDetail: ({ columns, data }) => {
    return (
      <p>
        {columns.map((column, index) =>
          column.title != "" ? (
            <p key={index} style={{ marginBottom: ".25em" }}>
              <b>{`${column.title} : `}</b>
              {column.render
                ? column.render(data[column.dataIndex], data, index)
                : data[column.dataIndex]}
            </p>
          ) : null
        )}
      </p>
    );
  },
};

export { ColumnHelper, RowHelper };
